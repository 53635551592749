<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card title="核单详情" :bordered='false' >
      <span slot='extra'>
        <template>
<!--          自提的 v-if=" order.status == 'delivering'"-->
          <a-space style="margin-left: 20px" >
                  <div>状态：<span style="font-size: large;color: #00A0E9">
                       <template v-if="order.auditStatus=='1'">待审核</template>
                      <template v-if="order.auditStatus=='2'">已审核</template>
                      <template v-if="order.auditStatus=='3'">已审核</template>
                      <template v-if="order.auditStatus=='4'">已删除</template>
                  </span></div>

          </a-space>
        </template>
      </span>

      <a-descriptions :column="4">
        <a-descriptions-item label='订单号'>{{order.id}}</a-descriptions-item>
        <a-descriptions-item label='下单时间'>{{ order.createTime }}</a-descriptions-item>
        <a-descriptions-item label='下单方式' :span="2">{{ getOrderType(order) }}</a-descriptions-item>

        <a-descriptions-item label='购买人姓名'>{{order.nickname}}</a-descriptions-item>
        <a-descriptions-item label='购买人手机号'>{{order.memberMobile}}</a-descriptions-item>
        <a-descriptions-item label='购买人身份'>{{ getTypeNameStr(order.memberType,order) }}</a-descriptions-item>
        <a-descriptions-item label='收款人'>
<!--          <span v-if="order.scene=='1'">{{ order.spreaderName }}</span>-->
<!--          <span v-if="order.scene=='2'">{{ order.merchantBankAccountName }}</span>-->
          {{order.bankAccountName}}
        </a-descriptions-item>

        <a-descriptions-item label='推荐人姓名'>{{ order.spreaderMasterName }}</a-descriptions-item>
        <a-descriptions-item label='推荐人手机号码'>{{order.spreaderMobile}}</a-descriptions-item>
        <a-descriptions-item label='推荐人身份'>{{ getTypeNameStrSpreader(order.spreaderType,order) }}</a-descriptions-item>
        <a-descriptions-item label='分公司简称'>{{order.memberAreaName}}</a-descriptions-item>


        <template v-if="order.isShowOne">
          <!--            商户录单只显示订单金额-->
          <template v-if="order.merchantRecord">
            <a-descriptions-item label='订单金额' :span="4">{{order.amount}}</a-descriptions-item>
          </template>
          <template v-else>
            <a-descriptions-item label='订单金额'>{{order.amount}}</a-descriptions-item>

            <a-descriptions-item label='购买人应付' v-if="order.merchantMember==order.member">{{order.originalMerchantAmount}}</a-descriptions-item>
            <a-descriptions-item label='购买人应付' v-else>{{order.amount}}</a-descriptions-item>

            <a-descriptions-item v-if="order.merchantMember==order.member" label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
            <a-descriptions-item v-else label='积分抵扣'>{{order.memberDiscount}}</a-descriptions-item>
            <a-descriptions-item v-if="order.merchantMember && order.merchantMember==order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
            <a-descriptions-item v-else></a-descriptions-item>

            <template v-if="order.merchantMember==order.member">
              <!--           商户直付给公司，要取商户字段，-->
              <a-descriptions-item label='购买人实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
              <a-descriptions-item label='购买人支付方式'>{{order.merchantPaymentMethodName }}</a-descriptions-item>
              <a-descriptions-item label='购买人支付时间' :span="2">{{order.merchantActualPaymentTime}}</a-descriptions-item>
            </template>
            <template v-else>
              <!--           其他情况取用户购买字段-->
              <a-descriptions-item label='购买人实付'><span class="color-red">{{order.consumeAmount}}</span></a-descriptions-item>
              <a-descriptions-item label='购买人支付方式'>{{order.paymentMethodName }}</a-descriptions-item>
              <a-descriptions-item label='购买人支付时间' :span="2">{{order.createTime}}</a-descriptions-item>
            </template>
          </template>
        </template>

        <template v-if="order.isShowTwo">
          <template v-if="order.applyId">
            <!--          首先判断为批量兑换订单,不显示商户相关信息，只显示，商户名称和负责人-->
            <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
            <a-descriptions-item label='负责人' :span="4">{{order.masterName}}</a-descriptions-item>
          </template>
          <template v-else>
            <!--         如果是其他订单，那么就显示所有商户信息-->
            <a-descriptions-item label='商户原始应付'>{{order.firstMerchantAmount}}</a-descriptions-item>
            <a-descriptions-item label='商户应付'>{{order.originalMerchantAmount}}</a-descriptions-item>
            <a-descriptions-item label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
            <a-descriptions-item v-if="order.merchantMember && order.merchantMember!=order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
            <a-descriptions-item v-else></a-descriptions-item>
            <a-descriptions-item label='商户实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
          </template>


          <a-descriptions-item label='商户支付方式'>{{order.merchantPaymentMethodName}}</a-descriptions-item>
          <a-descriptions-item label='商户支付时间'>{{order.merchantActualPaymentTime}}</a-descriptions-item>
          <!--            merchantPaymentTime-->
          <a-descriptions-item label='所在地区'>{{order.area}}</a-descriptions-item>


        </template>

        <template v-if="order.isShowThree">
          <a-descriptions-item label='总销'>{{order.wealthQuotaMemberName}}</a-descriptions-item>
          <a-descriptions-item label='付款人姓名'>{{order.fuZeRen}}</a-descriptions-item>
          <a-descriptions-item label='转账凭证'>
            <img v-for="(item, index) in order.merchantPaymentProofList" :key="index" :preview="true" :src='item' style="margin-right: 20px;" width='100px' height="100px" />
          </a-descriptions-item>
          <a-descriptions-item label='转账方式'>{{order.bankTransBuyerName?'他人代付':'本人付'}}</a-descriptions-item>
        </template>

      </a-descriptions>
    </a-card>

    <a-card title="审核信息" :bordered='false' style="margin-top: 20px;">
      <a-descriptions :column="1">
        <template  v-for="(item, index) in order.integralRewardList" >
          <a-descriptions-item  :key="index" >{{item.remark}}:   {{item.memberName||' 未知 :'}}  {{item.integralType=='1'?'可用积分':'兑换积分'}}  {{item.value}}</a-descriptions-item>
        </template>
      </a-descriptions>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {STable, Ellipsis} from '@/components'
import {getMember, getMerchantMemberByMobile} from '@/api/member'
import {
  getMemberOrder,
  putAuditOrder,
  getAuditRecord,
  getMemberCash,
  refund,
  queryOrderDetail499, queryPartnerOrderDetail
} from '@/api/member-order'
import {getFuZeRen, getSpreaderMobileList, showOrderType} from '@/utils/util'
import moment from 'moment'

const formatType = 'YYYY-MM-DD HH:mm';

const columns1 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    width: 80,
    title: '变动后可用积分',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' },
    sorter: true,
  }
]

export default {
  name: 'AuditOrderEdit',

  data() {
    return {
      guiShuName:'',//归属名称
      ext4:0,//积分合计
      loading: false,
      profile: {},
      merchantProfile:{},
      order: {},
      record: [],
      font_class: "",
      yingFu: 0,
      formatType,
      columns1,
      mType:1,
      // loadData1: parameter => {
      //   parameter.pageNum = parameter.current;
      //   parameter.pageSize = parameter.size;
      //   delete parameter.current;
      //   delete parameter.size;
      //   let gsName = '';
      //   if(this.mType==='1'){
      //     let _profile = this.profile
      //     parameter.member = _profile.id;
      //     gsName= _profile.nickname
      //   }else{
      //     let _merchantProfile = this.merchantProfile
      //     parameter.member = _merchantProfile.id;
      //     gsName = _merchantProfile.masterName
      //   }
      //   this.guiShuName = gsName;
      //
      //   let params = Object.assign(parameter,this.queryParam, this.deliver1.form);
      //   // debugger
      //   return getMemberCash(params).then(datum => {
      //     this.ext4 = datum.ext4;
      //       // if(datum.records && datum.records.length>0){
      //       //   this.guiShuName = datum.records[0].userName
      //       // }
      //       return datum
      //     })
      // },


    }
  },
  components: {STable, Ellipsis},
  computed: {
    // id () {
    //   return this.$route.query.id;
    // },
  },
  activated() {
    this.id = this.$route.query.id;
    this.handleInitial(this.id );
  },
  created() {
    this.id = this.$route.query.id;
    // this.handleInitial(this.id );
  },
  methods: {
    handleInitial(id) {
      let params = {
        id: id
      }
      getMemberOrder(params).then(res => {
        // debugger
          res = showOrderType(res); //公共方法获取显示区域
          this.order = res;
      });
    },
    getTypeNameStr(type,record) {
      if(record.shareholder=='1'){
        //股东判断
        return '股东'
      }

      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        return '服务中心';
      }else if(type=='president') {
        return '运营总裁';
      }else if(type=='normal'){
        // debugger;
        if(record.level=='vip'){
          return '会员';
        }
        // else if(record.level=='president'){
        //   return '备选运营';
        // } else if(record.level=='center'){
        //   return '备选服务';
        // }
        return '用户';
      }else{
        return '';
      }
    },
    getTypeNameStrSpreader(type,record) {
      if(record.shareholder=='1'){
        //股东判断
        return '股东'
      }

      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        return '服务中心';
      }else if(type=='president') {
        return '运营总裁';
      }else if(type=='normal'){
        // debugger;
        if(record.spreaderLevel=='vip'){
          return '会员';
        }
        // else if(record.spreaderLevel=='president'){
        //   return '备选运营';
        // } else if(record.spreaderLevel=='center'){
        //   return '备选服务';
        // }
        return '用户';
      }else{
        return '';
      }
    },
    //商户下单、用户下单、会员下单
    getOrderType(order){
      let merchantMember = order.merchantMember;
      let member = order.member;
      let memberLevel = order.memberLvel;

      // 用户下单 有两种 用直 用商，商户下单只有商直
      // 首先判断有无商户号，
      //    —如果没有说明用直
      // 如果有判断跟member相不相等
      //  —相等：商直
      //   —不相等：用商
      if(merchantMember && merchantMember !=member){// 用商 --用户下单，如果有（商户号）判断跟member不相等
          if(memberLevel=='vip'){
            return "会员下单";
          }else{
            return "用户下单";
          }
      }else if(merchantMember && merchantMember == member){//商户直付--商户下单 判断跟member相等
          return "商户下单";
      }else if(!merchantMember){//用户直付--商户下单 无（商户号）merchantMember
          if(memberLevel=='vip'){
            return "会员下单"
          }else{
            return "用户下单"
          }
      }

    }

  }
}
</script>
<style scoped>
.font-red {
  color: #E62734 !important;
}


</style>
